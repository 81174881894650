<template>
  <b-nav-item-dropdown
    class="dropdown-notification mr-25"
    menu-class="dropdown-menu-media"
    right
  >
    <template #button-content>
      <feather-icon
        :badge="totalNotif"
        badge-classes="bg-danger"
        class="text-body"
        icon="BellIcon"
        size="21"
      />
    </template>

    <!-- Header -->
    <li class="dropdown-menu-header">
      <div class="dropdown-header d-flex">
        <h4 class="notification-title mb-0 mr-auto">
          Notifications
        </h4>
        <b-badge
          pill
          variant="light-primary"
        >
          {{ totalNotif }} suivis
        </b-badge>
      </div>
    </li>

    <!-- Notifications -->
    <vue-perfect-scrollbar
      :settings="perfectScrollbarSettings"
      class="scrollable-container media-list scroll-area"
      tagname="li"
    >
      <!-- System Notifications -->
      <b-link>
        <b-media>
          <template #aside>
            <b-avatar
              size="32"
              variant="light-danger"
            >
              <feather-icon icon="ActivityIcon" />
            </b-avatar>
          </template>
          <p class="media-heading">
            <span class="font-weight-bolder">
              <span v-if="metrologyLate">{{ metrologyLate.length }}</span> Étalonnage échus
            </span>
          </p>
          <small class="notification-text">Urgent</small>
        </b-media>
      </b-link>
      <b-link>
        <b-media>
          <template #aside>
            <b-avatar
              size="32"
              variant="light-info"
            >
              <feather-icon icon="ShieldIcon" />
            </b-avatar>
          </template>
          <p class="media-heading">
            <span class="font-weight-bolder">
              <span v-if="lugniumLate">{{ lugniumLate.length }}</span> Lugnium terminés
            </span>
          </p>
          <small class="notification-text">A relancer</small>
        </b-media>
      </b-link>
      <b-link
        :to="{ name: 'sav' }"
      >
        <b-media>
          <template #aside>
            <b-avatar
              size="32"
              variant="light-warning"
            >
              <feather-icon icon="AlertTriangleIcon" />
            </b-avatar>
          </template>
          <p class="media-heading">
            <span class="font-weight-bolder">
              <span v-if="savTodo">{{ savTodo.length }}</span> SAV à faire
            </span>
          </p>
          <small class="notification-text">A faire</small>
        </b-media>
      </b-link>
      <b-link
        :to="{ name: 'sav' }"
      >
        <b-media>
          <template #aside>
            <b-avatar
              size="32"
              variant="light-danger"
            >
              <feather-icon icon="AlertTriangleIcon" />
            </b-avatar>
          </template>
          <p class="media-heading">
            <span class="font-weight-bolder">
              <span v-if="savLate">{{ savLate.length }}</span> SAV en retard
            </span>
          </p>
          <small class="notification-text">Urgent</small>
        </b-media>
      </b-link>
    </vue-perfect-scrollbar>
  </b-nav-item-dropdown>
</template>

<script>
import {
  BNavItemDropdown, BBadge, BMedia, BLink, BAvatar,
} from 'bootstrap-vue'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    BNavItemDropdown,
    BBadge,
    BMedia,
    BLink,
    BAvatar,
    VuePerfectScrollbar,
  },
  directives: {
    Ripple,
  },
  props: {
    lugniumLate: {
      type: Array,
      default: () => {},
    },
    metrologyLate: {
      type: Array,
      default: () => {},
    },
    savLate: {
      type: Array,
      default: () => {},
    },
    savTodo: {
      type: Array,
      default: () => {},
    },
    totalNotif: {
      type: Number,
      default: () => {},
    },
  },
  data() {
    return {
      /* eslint-disable global-require */
      perfectScrollbarSettings: {
        maxScrollbarLength: 60,
        wheelPropagation: false,
      },
    }
  },
}
</script>
