<template>
  <div class="navbar-container d-flex content align-items-center">

    <!-- Nav Menu Toggler -->
    <ul class="nav navbar-nav d-xl-none">
      <li class="nav-item">
        <b-link
          class="nav-link"
          @click="toggleVerticalMenuActive"
        >
          <feather-icon
            icon="MenuIcon"
            size="21"
          />
        </b-link>
      </li>
    </ul>

    <!-- Left Col -->
    <div class="bookmark-wrapper align-items-center flex-grow-1 d-none d-lg-flex">
      <dark-Toggler class="d-none d-lg-block" />
    </div>

    <b-navbar-nav class="nav align-items-center ml-auto">
      <notification-dropdown
        v-if="totalNotif > 0"
        :lugnium-late="app.lugniumLate"
        :metrology-late="app.metrologyLate"
        :sav-late="savLate"
        :sav-todo="savTodo"
        :total-notif="totalNotif"
      />
      <b-nav-item-dropdown
        right
        toggle-class="d-flex align-items-center dropdown-user-link"
        class="dropdown-user"
      >
        <template #button-content>
          <div class="d-sm-flex d-none user-nav">
            <p class="user-name font-weight-bolder mb-0">
              {{ user }}
            </p>
            <div
              v-if="permissions && permissions.includes('superadmin')"
            >
              <b-badge
                variant="light-primary"
                pill
              >
                superadmin
              </b-badge>
            </div>
            <div
              v-if="permissions && !permissions.includes('superadmin') && permissions.includes('admin')"
            >
              <b-badge
                variant="light-primary"
                pill
              >
                admin
              </b-badge>
            </div>
            <div
              v-if="permissions && !permissions.includes('superadmin') && !permissions.includes('admin') && upermissions.includes('user')"
            >
              <b-badge
                variant="light-primary"
                pill
              >
                user
              </b-badge>
            </div>
          </div>
        </template>

        <b-dropdown-item
          v-if="userId"
          :to="{ name: 'users-view', params: { id: userId } }"
          link-class="d-flex align-items-center"
        >
          <feather-icon
            size="16"
            icon="UserIcon"
            class="mr-50"
          />
          <span>Profil</span>
        </b-dropdown-item>

        <b-dropdown-divider />

        <b-dropdown-item
          link-class="d-flex align-items-center"
          @click="logOut"
        >
          <feather-icon
            size="16"
            icon="LogOutIcon"
            class="mr-50"
          />
          <span>Déconnexion</span>
        </b-dropdown-item>
      </b-nav-item-dropdown>
    </b-navbar-nav>
  </div>
</template>

<script>
import {
  BLink, BNavbarNav, BNavItemDropdown, BDropdownItem, BBadge,
  BDropdownDivider,
} from 'bootstrap-vue'
import { mapState } from 'vuex'
import DarkToggler from '@core/layouts/components/app-navbar/components/DarkToggler.vue'
import NotificationDropdown from './NotificationDropdown.vue'

export default {
  components: {
    BLink,
    BNavbarNav,
    BNavItemDropdown,
    BDropdownItem,
    BBadge,
    BDropdownDivider,

    // Navbar Components
    DarkToggler,
    NotificationDropdown,
  },
  props: {
    toggleVerticalMenuActive: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      user: null,
      permissions: null,
      userId: null,
      me: localStorage.getItem('username').split('@')[0],
    }
  },
  computed: {
    ...mapState(['app']),
    savTodo() {
      return this.$store.state.app.savTodo.filter(sav => sav.personInCharge.toLowerCase().includes(this.me))
    },
    savLate() {
      return this.$store.state.app.savLate.filter(sav => sav.personInCharge.toLowerCase().includes(this.me))
    },
    totalNotif() {
      const total = this.app.lugniumLate.length + this.app.metrologyLate.length + this.savLate.length + this.savTodo.length
      return total
    },
  },
  mounted() {
    this.user = localStorage.getItem('username')
    this.userId = localStorage.getItem('userId')
    this.permissions = localStorage.getItem('permissions')
  },
  methods: {
    async logOut() {
      this.$store.dispatch('auth/logout')
      this.$router.push({ name: 'login' })
    },
  },
}
</script>
