<template>
  <ul>
    <component
      :is="resolveNavItemComponent(item)"
      v-for="item in displayMenu"
      :key="item.header || item.title"
      :item="item"
    />
  </ul>
</template>

<script>
import { resolveVerticalNavMenuItemComponent as resolveNavItemComponent } from '@core/layouts/utils'
import { provide, ref } from '@vue/composition-api'
import store from '@/store'
import VerticalNavMenuHeader from '../vertical-nav-menu-header'
import VerticalNavMenuLink from '../vertical-nav-menu-link/VerticalNavMenuLink.vue'
import VerticalNavMenuGroup from '../vertical-nav-menu-group/VerticalNavMenuGroup.vue'

export default {
  components: {
    VerticalNavMenuHeader,
    VerticalNavMenuLink,
    VerticalNavMenuGroup,
  },
  // props: {
  //   items: {
  //     type: Array,
  //     required: true,
  //   },
  // },
  setup() {
    provide('openGroups', ref([]))

    return {
      resolveNavItemComponent,
    }
  },
  // Modif marine
  computed: {
    displayMenu() {
      let menu = null
      if (store.state.auth.role === 1) {
        menu = [
          {
            title: 'Dashboard',
            route: 'home',
            icon: 'HomeIcon',
          },
          {
            title: 'Utilisateurs',
            route: 'users',
            icon: 'UserIcon',
          },
          {
            title: 'Devices',
            route: 'devices',
            icon: 'ListIcon',
          },
          {
            header: 'Suivis',
          },
          {
            title: 'SAV',
            route: 'sav',
            icon: 'ToolIcon',
          },
          {
            title: 'Locations',
            route: 'locations',
            icon: 'GitPullRequestIcon',
          },
          {
            title: 'Fabrications',
            route: 'manufacturings',
            icon: 'LayersIcon',
          },
          {
            title: 'Calendrier',
            route: 'calendar',
            icon: 'CalendarIcon',
          },
          {
            title: 'Evolutions',
            route: 'evolutions',
            icon: 'TrendingUpIcon',
          },
          {
            header: 'Paramètres',
          },
          {
            title: 'N° de  serie',
            route: 'serialnumbers',
            icon: 'FolderPlusIcon',
          },
          {
            title: 'Types de device',
            route: 'device-types',
            icon: 'InboxIcon',
          },
          {
            title: 'Traductions',
            route: 'translations',
            icon: 'GlobeIcon',
          },
          {
            title: 'Versions',
            route: 'releases',
            icon: 'PlayCircleIcon',
          },
        ]
      } else {
        menu = [
          {
            title: 'Dashboard',
            route: 'home',
            icon: 'HomeIcon',
          },
        ]
      } return menu
    },
  },
}
</script>
